<template>
	<section class="cont">
		<el-row class="crumbs-box">
			<el-breadcrumb separator-class="el-icon-arrow-right">
				<el-breadcrumb-item>{{this.$route.path == '/ebooking_room_type_manage'?'eBooking':'PMS'}}</el-breadcrumb-item>
				<!-- <el-breadcrumb-item>房间管理</el-breadcrumb-item> -->
				<el-breadcrumb-item>房型管理</el-breadcrumb-item>
			</el-breadcrumb>
		</el-row>
		<el-row class="content-box">
			<!-- 查询框   -->
			<el-row class="search-box">
				<el-row class="search-row">
					<el-row class="search-item">
						<label>关键词：</label>
						<el-input class="width-220" placeholder="房型名称" v-model="roomTypeName">
						</el-input>
					</el-row>
					<el-button class="bg-gradient" type="primary" @click="getfroollist">搜索</el-button>
					<el-button type="primary" plain @click="handleReset">重置</el-button>
				</el-row>
			</el-row>
			<!-- 主体内容 -->
			<el-row class="table-box">
				<!-- 表格按钮 -->
				<div class="table-head-btn">
					<el-row>
					</el-row>
					<el-row class="right-btn">
						<el-button class="bg-gradient" type="primary" style="margin-left: 20px" @click="jump">添加房型</el-button>
						<el-button type="primary" v-if="this.multipleSelection != '' " @click="deleteroomtypelist">批量删除</el-button>
					</el-row>
				</div>
				<!-- 表格 -->
				<el-table ref="multipleTable" :data="froollist" tooltip-effect="dark" border fit @selection-change="handleSelectionChange">
					<el-table-column type="selection"></el-table-column>
					<el-table-column prop="roomTypeName" label="房型名称"></el-table-column>
					<el-table-column prop="roomTypePrice" label="门市价"> </el-table-column>
					<el-table-column prop="deposit" label="押金"></el-table-column>
					<template v-if="!btnshow">
						<el-table-column prop="bedNum" label="床位量"></el-table-column>
						<el-table-column prop="bedNum" label="状态">
							<template slot-scope='scope'>
								<span>{{scope.row.state=='YES'?'禁用':'启用'}}</span>
							</template>
						</el-table-column>
					</template>
					<el-table-column prop="roomTotalNum" label="房间数量"></el-table-column>
					<el-table-column prop="outsideline" label="排序">
						<template slot-scope="scope">
							<div v-if="scope.row.editshow == false">
								<span>{{scope.row.sortNo}}</span>
								<el-button type="text" @click="editsortno(scope.row,scope.$index)"><i class="el-icon-edit"></i></el-button>
							</div>
							<div v-else>
								<el-input @blur="onInputBlur(scope.row,scope.$index)" v-model="scope.row.sortNo"></el-input>
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="address" width="290" label="操作">
						<template slot-scope="item">
							<el-button type="text" @click="preservation(item.row)" v-if='btnshow' round>查看</el-button>
							<el-button type="text" @click="changeStatus(item.row.id,item.row.state)" v-if='!btnshow' round>{{item.row.state=='YES'?'启用':'禁用'}}</el-button>
							<el-button type="text" @click="emititem(item.row.id)" round>编辑</el-button>
							<el-button type="text" @click="deleteroomtype(item.row.id,item.row.roomTotalNum)" round>删除</el-button>
						</template>
					</el-table-column>
				</el-table>
				<!--查看弹窗-->
				<el-dialog title="查看房型统计" :visible.sync="dialogTableVisible">
					<el-button class="bg-gradient" type="primary" @click="addroomdag('添加')">添加房间</el-button>
					<el-table :data="roomlist">
						<el-table-column property="roomNo" label="房间号" width="150"></el-table-column>
						<el-table-column property="sortNo" label="排序" width="200"></el-table-column>
						<el-table-column property="remark" label="备注"></el-table-column>
						<el-table-column property="address" label="操作">
							<template slot-scope="scope">
								<el-button type="text" @click="lookat(scope.row,'编辑')">修改</el-button>
								<el-button type="text" @click="deleteroom(scope.row.id)">删除</el-button>
							</template>
						</el-table-column>
					</el-table>
					<el-dialog width="30%" @closed="closed" :visible.sync="addroomVisible" append-to-body>
						<div slot="title" class="header-title">
							<span class="title-name">{{btntype}}房间</span>
						</div>

						<el-form :model="form" :rules="rules" ref="form" label-width="120px">
							<el-form-item label="房间类型" prop="roomType">
								{{form.roomTypeName}}
							</el-form-item>
							<el-form-item label="房间单元/楼栋" prop="buildUnitIdQ">
								<el-cascader :key="buildKey" v-model="buildUnitIdQ" :options="buildList" @change="changeBuilding" :disabled="disabled" clearable class="width-165 m-right-10"></el-cascader>
							</el-form-item>

							<el-form-item label="所属楼层" prop="floor">
								<el-select v-model="form.floor" placeholder="请选择" style="width: 100%;">
									<el-option v-for="(item,index) in floors" :disabled="disabled" :key="index" :label="item.value" :value="item.value">{{item.value}}</el-option>
								</el-select>
							</el-form-item>

							<el-form-item label="房号" prop="roomNo">
								<el-input v-model="form.roomNo" placeholder="房号" maxlength="9" show-word-limit></el-input>
							</el-form-item>

							<el-form-item label="房间描述" prop="remark">
								<el-input type="textarea" v-model="form.remark" placeholder="备注不超过9个字" maxlength="9" show-word-limit style="width:100%"></el-input>
							</el-form-item>
						</el-form>
						<div class="btn-box">
							<el-button @click="addroomVisible = false">取消</el-button>
							<el-button type="primary" v-if="btntype == '编辑' " @click="editroom">确定</el-button>
							<el-button type="primary" v-else @click="addroomlist">确定</el-button>
						</div>
					</el-dialog>
				</el-dialog>
			</el-row>
		</el-row>
	</section>
</template>

<script>
	import { mapState } from "vuex";
	import { urlObj } from '@/api/interface'
  import { pms } from '@/api/interface/pms'
	export default {
		data() {
			return {
				froollist: [],
				buildList: [],
				buildUnitIdQ: [],
				buildKey: '',
				floors: [],
				roomlist: [],
				emptyFloor: [],
				disabled: false,
				frool: '',
				idlist: [],
				state:'',
				addroomVisible: false,
				dialogTableVisible: false,
				editshow: true,
				multipleSelection: [],
				roomTypeSource: '',
				roomTypeName: '',
				page: 1,
				btntype: '',
				btnshow: false,
				id: '',
				floorinfo: '',
				form: {
					floor: '',
					roomNo: '',
					roomTypeName: '',
					hotelId: '',
					roomTypeId: '',
					buildUnitId: '',
					remark: '',
					id: '',
				},
				rules: {
					floor: [{
						required: true,
						message: '请选择楼层',
						trigger: 'blur'
					}, ],
					roomNo: [{
						required: true,
						message: '请输入房号',
						trigger: 'blur'
					}, ],
				}
			}
		},
		computed: {
			...mapState(['hotelInfo'])
		},
		mounted() {
			this.limit = sessionStorage.getItem('pageSize') * 1
			if(this.$route.path == '/ebooking_room_type_manage') {
				this.roomTypeSource = 'EBOOKING'
				this.btnshow = false
			} else {
				this.roomTypeSource = 'PMS'
				this.btnshow = true
			}
			this.getfroollist()

		},
		watch: {
			buildList(newVal) {
				if(newVal.length !== 0) this.buildKey = newVal[0].value
			},
			hotelInfo: {
				handler(newVal, oldVal) {
					if(newVal.id !== oldVal.id) {
						this.hotelId = this.hotelInfo.id
						this.form.hotelId = this.hotelInfo.id
						this.getfroollist()
					}
				},
			}
		},
		methods: {
			//获取房型列表
			getfroollist() {
				const url = pms.pmsRoomTypes
				const param = {
					hotelId: this.hotelInfo.id,
					roomTypeName: this.roomTypeName,
					roomTypeSource: this.roomTypeSource,
				}
				this.$axios.post(url, param, 'json').then(res => {
					if(res.success) {
						this.froollist = res.records
						this.froollist.map(item => {
							item.editshow = false
							if(!item.hasOwnProperty('state')) {
								item.state = 'NO'
							}
						})
					}
				})
			},

			// 获取当前楼层列表
			getFloors(unitVal) {
				const url = urlObj.getUnit + '/' + unitVal
				this.$axios.get(url, {}).then(res => {
					if(res.success) {
						let data = res.records
						this.floorinfo = res.records
						let beginFloor = data.beginFloor
						let endFloor = data.endFloor
						if(!endFloor || endFloor <= 0) return
						let floors = JSON.parse(JSON.stringify(this.emptyFloor))
						let obj = {}
						for(let i = beginFloor; i <= endFloor; i++) {
							obj.value = i
							obj.label = i
							floors.push(obj)
							obj = {}
						}
						this.floors = floors.filter(item => item.value != 0)
						this.floorinfo.excludeFloor = this.floorinfo.excludeFloor.split(',') || ""
						for(let j = 0; j < this.floorinfo.excludeFloor.length; j++) {
							for(let i = 0; i < this.floors.length; i++) {
								if(Number(this.floors[i].value) == Number(this.floorinfo.excludeFloor[j])) {
									this.floors.splice(i, 1);
								}
							}
						}
					}
				}).catch(error => {
					this.$message.error(error)
				})
			},

			// 获取楼栋单元层级
			changeBuilding(val) {
				this.form.buildUnitId = val[1]
				this.getFloors(val[1])
			},

			//新增房型
			jump() {
				if(this.$route.path == '/ebooking_room_type_manage') this.$router.push("/add_ebookingroom_type_manage")
				if(this.$route.path == '/room_type_manage') this.$router.push("/add_room_type_manage")
			},

			//获取房间列表
			getRooms() {
				const url = pms.pmsRoomList
				const param = {
					hotelId: this.hotelInfo.id,
					roomTypeId: this.form.roomTypeId,
					roomTypeSource: this.roomTypeSource,
				}
				this.$axios.post(url, param, 'json').then(res => {
					if(res.success) {
						this.roomlist = res.records

					}
				})

			},

			//获取栋列表
			getBuildList() {
				const url = urlObj.buildAndUnitList + '/' + this.hotelInfo.id
				const param = {}
				this.$axios.get(url, param).then(res => {
					if(res.success) {
						let buildList = res.records
						this.buildList = buildList.map(item1 => {
							let children = []
							if(item1.buildUnits.length > 0) {
								children = item1.buildUnits.map(item2 => {
									return {
										value: item2.id,
										label: item2.unitName
									}
								})
							}
							return {
								value: item1.id,
								label: item1.buildName,
								children
							}
						})

					}
				})
			},

			//修改房间类型
			emititem(id) {
				if(this.$route.path == '/ebooking_room_type_manage') {
					this.$router.push({
						path: '/add_ebookingroom_type_manage',
						query: {
							id
						}
					})
				} else {
					this.$router.push({
						path: '/add_room_type_manage',
						query: {
							id
						}
					})
				}
			},

			//弹窗关闭后触发事件
			closed() {
				this.buildUnitIdQ = []
				this.form.floor = '';
				this.form.roomNo = '';
				this.form.buildUnitId = '';
				this.form.remark = '';
				this.form.id = '';

			},

			//查看
			preservation(val) {
				this.form.roomTypeName = val.roomTypeName
				this.form.hotelId = val.hotelId
				this.form.roomTypeId = val.id
				this.getRooms()
				this.getBuildList()
				this.dialogTableVisible = true
			},

			handleReset() {
				this.roomTypeName = ''
			},

			//弹出添加弹窗
			addroomdag(btntype) {
				this.addroomVisible = true
				this.btntype = btntype
			},

			//弹窗内编辑
			lookat(val, btntype) {
				this.btntype = btntype
				this.form.roomTypeName = val.roomTypeName
				this.form.hotelId = val.hotelId
				this.form.remark = val.remark
				this.form.roomNo = val.roomNo
				this.form.floor = val.floor
				this.buildUnitIdQ = val.buildUnitId
				this.form.buildUnitId = val.buildUnitId
				this.form.roomTypeId = val.roomTypeId
				this.getBuildList()
				this.getFloors(val.buildUnitId)
				this.disabled = true
				this.form.id = val.id
				this.addroomVisible = true
			},

			//添加房间
			addroomlist() {
				const url = pms.addroomlist
				const param = this.form
				this.$axios.post(url, param, 'json').then(res => {
					if(res.success) {
						this.$message.success('添加成功')
						this.addroomVisible = false
						this.getRooms()
					}
				}).catch(error => {
					this.$message.error(error)
				})
			},

			//修改房间
			editroom() {
				const url = pms.editroomitem
				const param = this.form
				this.$axios.post(url, param, 'json').then(res => {
					if(res.success) {
						this.$message.success('修改成功')
						this.addroomVisible = true
						this.disabled = false
						this.getRooms()
					}
				}).catch(error => {
					this.$message.error(error)
				})
			},

			//显示修改排序输入框
			editsortno(val, index) {
				val.editshow = !val.editshow
				this.$set(this.froollist, index, val)
			},

			//输入框失去焦点修改排序
			onInputBlur(val, index) {
				console.log(val.sortNo)
				if(val.sortNo != undefined) {
					const url = pms.editroomtype
					const param = {
						hotelId: this.hotelInfo.id,
						sortNo: val.sortNo,
						id: val.id,
            roomTypeSource: this.roomTypeSource,
					}
					this.$axios.post(url, param, 'json').then(res => {
						if(res.status == 200) {
							val.editshow = false
							this.$set(this.froollist, index, val)
							this.getfroollist()
						}

					}).catch(error => {
						if(error.response.data.message == "当前酒店房型排序重复!") {
							val.editshow = false
							this.froollist[index].sortNo = undefined
							this.$set(this.froollist, index, val)
							this.getfroollist()
						}
					})

				} else {
					val.editshow = false
					this.$set(this.froollist, index, val)
				}

			},

			//表格选中
			handleSelectionChange(val) {
				this.multipleSelection = []
				for(let i = 0; i < val.length; i++) {
					this.multipleSelection.push(val[i].id)
				}
			},

			//修改房型状态
			changeStatus(id, state) {
				if(state == 'NO') this.state = 'YES'
				if(state == 'YES') this.state = 'NO'
				const url = pms.updateebookingState
				const param = {
					state: this.state,
					id: id,
          roomTypeSource: this.roomTypeSource,

				}
				this.$axios.post(url, param, 'json').then(res => {
					this.getfroollist()
					this.$message.success('修改成功')
				})
			},

			//删除房间
			deleteroom(id) {
				this.$confirm('确定要删除房型吗？', '删除提示', {
						distinguishCancelAndClose: true,
						confirmButtonText: '确定',
						cancelButtonText: '放弃'
					}).then(() => {
						const url = pms.deletelroom + id
						const param = {
							roomTypeSource: this.roomTypeSource,
						}
						this.$axios.get(url, param).then(res => {
							this.getfroollist()
							this.dialogTableVisible = false
						})
						this.$message({
							type: 'success',
							message: '删除成功'
						});
					})
					.catch(action => {
						this.$message({
							type: 'info',
							message: action === 'cancel' ?
								'已放弃删除' : '停留在当前页面'
						})
					});
			},

			//批量删除房型
			deleteroomtypelist() {

				let id = this.multipleSelection
				this.$confirm('确定要删除房型吗？', '删除提示', {
						distinguishCancelAndClose: true,
						confirmButtonText: '确定',
						cancelButtonText: '放弃'
					}).then(() => {
						const url = pms.deleteroomtype + id
						const param = {}
						this.$axios.get(url, param).then(res => {
							this.getfroollist()
						})
						this.$message({
							type: 'success',
							message: '删除成功'
						});
					})
					.catch(action => {
						this.$message({
							type: 'info',
							message: action === 'cancel' ?
								'已放弃删除' : '停留在当前页面'
						})
					});
			},

			//删除房型
			deleteroomtype(id, num) {
				if(num != 0) {
					this.$message.error('该房型下有房间不能删除')
				} else {
					this.$confirm('确定要删除房型吗？', '删除提示', {
							distinguishCancelAndClose: true,
							confirmButtonText: '确定',
							cancelButtonText: '放弃'
						})
						.then(() => {
							const url = pms.deleteroomtype + id
							const param = {}
							this.$axios.get(url, param).then(res => {
								this.getfroollist()
							})
							this.$message({
								type: 'success',
								message: '删除成功'
							});
						})
						.catch(action => {
							this.$message({
								type: 'info',
								message: action === 'cancel' ?
									'已放弃删除' : '停留在当前页面'
							})
						});
				}
			}

		},
	}
</script>

<style scoped>
	/*.page{*/
	/*  width: 100%;*/
	/*  height: 100%;*/
	/*  display: flex;*/
	/*}*/
	/*.right{*/
	/*  !*margin-left: 30px;*!*/
	/*  width:100%;*/
	/*}*/
	/*.right-box{*/
	/*  min-height: 360px;*/
	/*  max-height: 100%;*/
	/*  min-width: 900px;*/
	/*  max-width: 100%;*/
	/*  background: #fff;*/
	/*  !*padding:20px 50px;*!*/
	/*}*/

	.right-title {
		height: 40px;
		padding: 25px 10px;
		line-height: 40px;
	}

	.froolnum {
		color: #615f5f;
		font-size: 16px;
	}

	.btn-box {
		text-align: right;
	}

	.delet_text {
		padding: 20px;
		text-align: center;
		display: inline-block;
		font-size: 18px;
		width: 95%;
	}

	/deep/ .el-table th.gutter {
		/*
  * 解决element-ui 表格篡位的问题 👇
  */
		display: table-cell!important;
	}

	/deep/ .el-table--enable-row-transition .el-table__body td {
		/*text-align: center;*/
	}

	/deep/ .el-table th.is-leaf,
	.el-table td {
		/*text-align: center;*/
		background: #f5f8f9;
	}
</style>
